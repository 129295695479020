// _level1.scss
.level1 .article-header h1 {
  margin-bottom: 3rem;
}
.level1 .chapo {
  font-size: 128.5717%;
  line-height: normal;
  margin-bottom: 3rem;
  //font-style: italic;
}
.level1-content {
  h2 {
    color: $grey-color;
    font-family: $serif-font;
    margin-bottom: 3rem;
    font-size: 2.4rem;
    border-bottom: solid 1px $light-color;
    font-style: italic;
  }
  > div > section > ul {
    list-style-type: none;
    margin: 1.5rem 0 0;
    padding: 0;
    > li {
      display: block;
      margin: 0 0 1.5rem;
      padding: 0 0 1rem;
      border-bottom: solid 1px $hover-font-color;
    }
  }
  h3 {
    font-size: 2rem;
    a {
      text-decoration: none;
      .media {
        font-size: 80%;
        color: $gray-color;
      }
    }
    .free-or-not {
      display: block;
      float: right;
      margin-left: 2rem;
      width: 36px;
      height: 14px;
      margin-top: .25em;
      background-image: url(../medias/img/free-or-not.svg);
      background-repeat: no-repeat;
      background-position: 0 -14px;
      &.free {
        background-position: 0 0;
      }
    }
  }
  .article-content {
    @extend .clearfix;
    float: left;
    width: 100%;
    .img {
      @include wsize('tablet') {
        float: left;
        width: $img_width;
        margin-right: 1.5rem;
      }
      margin-bottom: 1.5rem;
      text-align: center;
      img {
        max-width: 100%;
      }
    }
    .img + .abstract {
      @include wsize('tablet') {
        margin-left: calc($img_width + 1.5rem);
      }
    }
  }
  &.search-results {
    h3 {
      margin-bottom: 0;
    }
    p.type {
      //margin-left: 2rem;
      color: $dark-color;
      font-weight: 500;
    }
  }
}
ul.members-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: block;
  @extend .clearfix;
  li.member {
    display: block;
    padding: 1em;
    @include wsize('tablet') {
      float: left;
      width: 50%;
      &:nth-child(odd) {
        clear: left;
      }
    }
    > div {
      @extend .clearfix;
    }
    @include wsize('tablet') {
      &:nth-child(odd) {
        border-right: solid 1px $light-gray-color;
      }
      &:nth-child(even) {
        border-left: solid 1px $light-gray-color;
        margin-left: -1px;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  .who {
    float: left;
    width: 80%;
    @include wsize('tablet') {
      width: 66.6666%;
    }
  }
  .name {
    font-weight: bold;
  }
  .company {
    padding-left: 1.6em;
  }
  .email {
    float: left;
    width: 20%;
    @include wsize('tablet') {
      width: 33.3333%;
    }
    padding-left: 1em;
    padding-right: 1em;
    text-align: right;
    a {
      display: inline-block;
      text-decoration: none;
      &:before {
        @include fa(1em);
        content: "\f003";
      }
    }
  }
}
