/* Navbar */
header {
  nav {
    &.navbar-default {
      border: 0;
      background: none;
      box-shadow: none;
      position: absolute;
      top: 14px;
      right: 0;
      left: 0;
      min-height: inherit;
      margin: 0;
      .navbar-nav {
        float: none;
        margin: 7.5px -15px;
        background: $bg-white-color;
        > .active {
          > a {
            background: none;
            box-shadow: none;
            color: $primary-color;
            background: none;
            background-color: inherit;
            &:focus,
            &:hover {
              color: $primary-color;
              background: none;
              background-color: inherit;
            }
          }
        }
        li {
          a {
            color: $menu-font-color;
            font-weight: 500;
            font-size: 18px;
            padding: 10px 15px 10px 24px;
            font-family: $sans-font;
            text-decoration: none;
            &:before {
              content: "\f101";
              font: normal normal normal 14px/1 "FontAwesome";
              color: $primary-color;
              margin-right: 15px;
            }
          }
          &.logout {
            padding-right: 15px;
            padding-left: 24px;
            font-size: 1.6rem;
            div {
              border-bottom: solid 1px $hover-font-color;
              padding-bottom: 1rem;
              margin-bottom: 1rem;
              text-align: right;
              a {
                padding: 0;
                display: inline;
                &:before {
                  display: none;
                }
                &:after {
                  content: "\f08b";
                  font-family: "FontAwesome";
                  line-height: 1;
                  color: $primary-color;
                  margin-left: .25rem;
                }
              }
            }
          }
        }
        > li {
          float: none;
          z-index: 9;
          background: $bg-white-color ;
          > a {
            &:focus,
            &:hover {
              color: $primary-color;
            }
            &:before {
              content: "\f101";
              font: normal normal normal 14px/1 "FontAwesome";
              color: $primary-color;
              margin-right: 15px;
            }
          }
          &.open {
            > a {
              font-family: $sans-font;
              background: none;
              box-shadow: none;
              color: $primary-color;
              &:before {
                content: "\f103";
                font: normal normal normal 14px/1 "FontAwesome";
                color: $primary-color;
                margin-right: 15px;
              }
              .bord-bttm {
                border-bottom: 1px solid $bg-primary-color;
                display: inline-block;
                width: 93%;
                right: 0;
                float: right;
                margin-top: 7px;
              }
              &:focus,
              &:hover {
                color: $primary-color;
                background: none;
                box-shadow: none;
              }
            }
            ul {
              &.dropdown-menu {
                padding: 0 0 0 35px;
                position: static;
                float: none;
                width: auto;
                margin-top: 0;
                background-color: transparent;
                border: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
                > li {
                  > a {
                    color: $menu-font-color;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 20px;
                    padding: 5px 15px 5px 25px;
                    &:focus,
                    &:hover {
                      color: $primary-color;
                      background: none;
                      box-shadow: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
      @include wsize('tablet') {
        max-width: 375px;
        width: 100%;
        left: inherit !important;
        right: -15px;
      }
    }
    &.navbarGlobal {
      background: $bg-white-color;
      border-radius: 0;
      z-index: 999;
      box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.25);
      @include wsize('tablet') {
        max-width: 375px;
      }
      .navbar-header {
        p {
          display: block;
          @include wsize('laptop') {
            display: block;
          }
        }
      }
    }

    p {
      display: none;
      font-size: 10.41px;
      font-weight: 500;
      clear: both;
      text-align: center;
      text-transform: uppercase;
      color: $gray-color;
      position: absolute;
      width: 52px;
      right: 0;
      bottom: -4px;
      &.showTxtMenu {
        display: block;
        margin-bottom: 0;
        @include wsize('tablet') {
          display: block;
        }
      }
      @include wsize('tablet') {
        margin-right: 14px;
      }
      @include wsize('laptop') {
        display: block;
        letter-spacing: 0.7px;
        font-size: 16.87px;
        margin-right: 15px;
      }
    }
    .navbar-header {
      float: none;
      position: relative;
      height: 65px;
      .navbar-toggle {
        width: 52px;
        height: 41px;
        background: url(../medias/img/menu-hover.svg) no-repeat;
        background-position: 0 -41px;
        z-index: 999;
        border-radius: 0;
        display: block !important;
        border: 0;
        border-color: inherit;
        margin-right: 0;
        padding: 0;
        margin-bottom: 0;
        .icon-bar {
          width: 31px;
          height: 5px;
          margin-top: 4px;
          background-color: $bg-primary-color;
          transition: all 0.2s;
          @include wsize('laptop') {
            width: 48px;
            height: 7px;
            margin-top: 7px;
          }
        }
        .top-bar { 
          transform: rotate(45deg);
          transform-origin: 0% 10%;
          @include wsize('tablet') {
            transform-origin: 10% 0%;
          }
        }
        .middle-bar {
          opacity: 0;
        }
        .bottom-bar {
          transform: rotate(-45deg);
          transform-origin: 10% 100%;
          @include wsize('tablet') {
            transform-origin: 0% 100%;
          }
        }
        &:focus,
        &:hover {
          background-color: transparent;
        }
        &.collapsed {
          background-position: 0 0;
          .top-bar {
            transform: rotate(0);
          }
          .middle-bar {
            opacity: 1;
          }
          .bottom-bar {
            transform: rotate(0);
          }
        }
        @include wsize('tablet') {
          margin-right: 15px;
        }
        @include wsize('laptop') {
          margin-top: 0;
          background-position: -3px -41px;
        }
      }
      @include wsize('tablet') {
        height: 69px;
      }
    }
    .navbar-collapse {
      border-top: 1px solid transparent;
      border-radius: 0;
      border: 0;
      box-shadow: none;
      &.in {
        overflow-y: auto !important;
        width: inherit;
        border-radius: 0;
        border: 0;
        box-shadow: none;
      }
      &.collapse {
        display: none !important;
        &.in {
          display: block !important;
        }
      }
    }
  }
}
