ol.breadcrumb {
  border-radius: 0;
  font-weight: 300;
  padding: 0;
  width: 100%;
  display: block;
  background: none;
  list-style-type: none;
  margin: 1.5rem 0;
  li { 
    display: inline-block;
    font-size: 14px;
    a { 
      font-size: 14px;
      text-decoration: none;
    }
    &.active {
      font-weight: bold;
    }
    &:first-child { 
      display: none;
      @include wsize('tablet') {
        display: inline-block;
      }
    }
  }
  & > li +li:before {
    display: none;
    font-weight: normal;
  }
  & > li + li +li:before {
      color: $gray-color;
      content: "\3e";
      display: inline-block;
  }
  @include wsize('tablet') {
    & > li +li:before {
      color: $gray-color;
      content: "\3e";
      display: inline-block;
    }
  }
}