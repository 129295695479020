$breakpoints: (
  'tablet': (min-width: 768px),
  'laptop': (min-width: 992px),
  'desktop': (min-width: 1200px)
);
@mixin wsize($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);
  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );
    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}
// Usage:
// @include wsize('tablet') { foo: bar; }
