// _calendar.scss

.calendar h1 {
  margin-bottom: 3rem;
}

.calendar table.responsive,
.calendar .pinned table {
  font-family: $sans-font;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $light-grey-color;
  @include wsize('tablet') {
    margin-bottom: 3rem;
  }
  @media print {
    margin-bottom: 3rem;
  }
  td,
  th {
    text-align: center;
    padding: 8px;
    line-height: 1;
    font-size: 1.4rem;
    @include wsize('tablet') {
      padding-left: 4px;
      padding-right: 4px;
    }
    @include wsize('laptop') {
      padding-left: 8px;
      padding-right: 8px;
    }
    &.text-left {
      text-align: left;
    }
    @media print {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }
  th {
    background: $grey-color;
    color: $white-font-color;
    &:first-child {
      background: $primary-color;
    }
    @media print {
      border-bottom: solid 1px $light-grey-color;
      border-right: solid 1px $light-grey-color;
    }
  }
  td {
    border-bottom: solid 1px $light-grey-color;
    border-right: solid 1px $light-grey-color;
    @include wsize('tablet') {
      width: 7.7%;
    }
  }
}
.calendar-content > .last-articles > section .no-page-break {
  page-break-inside: avoid;
}
