.revue-des-marques {
  // 1 - magasines' list
  h1.all-magasines {
    margin-bottom: 4rem;
  }
  div.all-magasines {
    margin-bottom: 4rem;
    .item {
      margin-bottom: 3rem;
      padding-bottom: 1.5rem;
      border-bottom: solid 1px $light-grey-color;
    }
    a.cover-link {
      display: block;
      text-align: center;
      text-decoration: none;
      color: $grey-color;
      font-weight: bold;
      line-height: 1;
      .img {
        display: block;
        max-width: 165px;
        margin-left: auto;
        margin-right: auto;
      }
      img {
        margin-bottom: 1.5rem;
        margin-left: auto;
        margin-right: auto;
      }
      .num,
      .title {
        display: block;
      }
      .num {
        color: $primary-color;
        margin-bottom: .5em;
      }
    }
    p.edito-link {
      margin: 1rem 0 2rem;
      a {
        text-decoration: none;
        font-size: 150%;
      }
    }
    .item-content .text-right a {
      font-family: $serif-font;
      font-size: 1.8rem;
      font-style: italic;
      text-decoration: none;
    }
  }
  .previous-magasines {
    text-align: center;
    a {
      display: inline-block;
      &:after {
        display: inline-block;
        margin-left: .5rem;
        font-family: FontAwesome;
        line-height: 1;
        vertical-align: super;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 50%;
        content: "\f14c";
      }
    }
  }
  // Table of content of one magasine
  .table-of-content {
    h2 {
      background: $revue-color;
      color: $white-color;
      padding: 0.3rem 1rem 0.2rem;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
      font-size: 2.4rem;
    }
    a {
      .img-responsive {
        box-shadow: 0 0 4px rgba(34, 25, 25, 0.25);
      }
      &:hover .img-responsive {
        box-shadow: none;
      }
    }
    h4 {
      font-size: 1.6rem;
      padding: 0;
      margin: 0.6rem 0;
      a {
        text-decoration: none;
        display: block;
      }
    }
    .article {
      margin-bottom: 1.5rem;
    }
  }
}
