@media print {
  html,
  body {
    width: auto;
    height: auto;
  }
  body {
    background: $white-font-color;
    &.home {
      min-height: 75cm;
    }
  }
  .content {
    padding-bottom: 0;
    width: 100%;
  }
  .container {
    width: 100%;
  }
  .site-header {
    .site-header-main {
      .navigation-top {
        .logo {
          a {
            width: 100%;
            margin: 0;
            text-align: center;
          }
        }
      }
      header {
        nav.navbar-default {
          display: none !important;
        }
      }
    }
  }
  blockquote, pre {
    border-top: none;
    border-right: none;
    border-bottom: none;
  }
  .site-header {
    .recherche {
      display: none; 
    }
  }
  .header-bottom,
  .carousel-bibliotheque {
    display: none;
  }
  a[href]:after {
    content: "";
  }
  .level1-content {
    .article-content {
      .img {
        float: left;
        width: $img_width;
        margin-right: 1.5rem;
      }
    }
  }
  .basic-content figure {
    max-width: 33.333%;
    margin-left: 2rem;
    float: right;
  }
  .revue-des-marques {
    .table-of-content {
      div[class*=' col-md-'],
      div[class^='col-md-'] {
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
      }
      .col-md-6 {
        width: 100%;
        clear: both;
        float: left;
      }
      .col-md-3 {
        width: 25%;
      }
      .col-md-9 {
        width: 75%;
      }
    }
  }
  .all-magasines {
    div[class*=' col-sm-'],
    div[class^='col-sm-'] {
      float: left;
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
    .col-sm-2 {
      width: 16.66666667%;
    }
    .col-sm-10 {
      width: 83.33333333%;
    }
  }
  .sidebar {
    display: none;
  }
}
