.basic-content { 
  font-size: $content-font-size;
  color: $regular-text-color;
  line-height: 1.4em;
  font-weight: 300;
  i,
  em {
    letter-spacing: $italic_letter_spacing;
  }
  h2 {
    // Chapeau public
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1em;
    text-align: justify;
  }
  p {
    margin-bottom: 1em;
    text-align: justify;
    color: $regular-text-color;
    &.text-right {
      text-align: right;
    }
    &.text-center {
      text-align: center;
    }
    &.text-left {
      text-align: left;
    }
  }
  p[align="right"] {
    text-align: right;
  }
  p[align="center"] {
    text-align: center;
  }
  p[align="left"] {
    text-align: left;
  }
  figure {
    display: block;
    max-width: 100%;
    margin-bottom: 1rem;
    text-align: center;
    @include wsize('tablet') {
      max-width: 33.333%;
      margin-left: 2rem;
      float: right;
      &.gauche,
      &.left {
        margin-left: 0;
        margin-right: 2rem;
        float: left;
      }
    }
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto 1rem;
    }
    figcaption {
      color: $gray-color;
      font-size: 14px;
      font-style: italic;
      margin: 0 0 1rem;
      letter-spacing: $italic_letter_spacing;
    }
  }
  .img-full {
    figure {
      max-width: 100%;
      float: none;
      margin-left: auto;
      margin-right: auto;
      @include wsize('tablet') {
        max-width: 100%;
      }
    }
  }
  .imprime { 
    a {
      font-weight: 500;
      font-size: $content-font-size;
      text-decoration: underline;
    }
  }
  ul {
    list-style-type: none;
    margin: 0 0 1.5rem 2rem;
    padding: 0;
    text-align: justify;
    li {
      position: relative;
      margin: 0 0 0 .5rem;
      padding: 0;
        &:before {
          @include fa(0.4em);
          position: absolute;
          top: 1.2em;
          left: -2em;
          color: $grey-color;
          content: "\f111";
        }
    }
  }
  blockquote {
    font-size: $content-font-size;
    font-style: italic;
    letter-spacing: $italic_letter_spacing;
    border-left: 2px solid $gray-color;
    margin-left: 1rem;
    padding-right: 0;
    p[align="right"] {
      font-size: .9em;
      text-align: right !important;
      color: $grey-color;
      font-style: italic;
      letter-spacing: $italic_letter_spacing;
      strong,
      b {
        font-weight: normal !important;
      }
      &:before {
        display: inline-block;
        content: "\2014\a0\a0";
      }
      &:after {
        display: inline-block;
        content: "\a0\a0\2014";
      }
    }
  }
}
.richtext {
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  h2 { // Intertitre pour l'extranet
    color: $primary-color;
    font-size: 1.8rem;
    font-weight: bold;
  }
  h3 { // Plan CR
    color: lighten($dark-color, 40%);
    font-size: 2rem;
    font-weight: bold;
  }
  h4 { // Plan CR 2
    color: lighten($dark-color, 55%);
    font-size: 1.9rem;
    font-weight: bold;
  }
  h5 { // Plan CR 3
    color: lighten($dark-color, 55%);
    font-size: 1.9rem;
    font-weight: bold;
    font-style: italic;
  }
  h6 { // Notes de bas de page
    color: lighten($dark-color, 10%);
    font-size: 1.3rem;
    font-weight: normal;
  }
  img {
    max-width: 100%;
    height: auto !important;
    &.centre,
    &.center {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
    }
    @include wsize('tablet') {
      &.droite,
      &.right {
        margin-left: 2rem;
        margin-right: 0;
        float: left;
        margin-bottom: 1rem;
      }
      &.gauche,
      &.left {
        margin-left: 0;
        margin-right: 2rem;
        float: left;
        margin-bottom: 1rem;
      }
    }
  }
  ul {
    clear: both;
  }
  table.cadre {
    margin-bottom: 1rem;
    @include wsize('tablet') {
      &.left,
      &.gauche {
        float: left;
        margin-right: 2rem;
        max-width: calc(50% - 1rem);
      }
      &.right,
      &.droite {
        float: right;
        margin-left: 2rem;
        max-width: calc(50% - 1rem);
      }
    }
    td {
      padding: 2rem;
      background: $almost-white-color;
    }
  }
  hr {
    border: none;
    background: transparent;
    height: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
    @extend .clearfix;
  }
}
