/* ----------------------------------------------------
  GLOBAL STRUCTURE  
------------------------------------------------------- */

body { 
  background: $bg-white-color;
  font-family: $sans-font;
}
.content {
  font-size: 1.6rem;
  position: relative;
  @include wsize('tablet') {
    padding-top: 0;
    padding-bottom: 100px;
  }
}
/* styles par defaut  */
h1 {
  color: $primary-color;
  font: normal normal normal 2.8rem/1 $serif-font;
  &:before{
    content: "\f111";
    font-family: FontAwesome;
    float: left;
    margin-right: 1rem;
  }
}
header{
  h1{
    &:before{
      display:none;
    }
  }
}
h2 {
  font-size: 21px;
  color: $third-color;
  font-family:$sans-font;
  @include wsize('laptop') {
    font-size: 29px;
  }
}
h3{
  font-size: 19px;
  color: $third-color;
  font-family:$sans-font;
  @include wsize('laptop') {
    font-size: 26px;
  }
}
h4{
  font-size: 16px;
  color: $third-color;
  font-family:$sans-font;
  @include wsize('laptop') {
    font-size: 23px;
  }
}
h5{
  font-size: 13px;
  color: $third-color;
  font-family:$sans-font;
  @include wsize('laptop') {
    font-size: 20px;
  }
}
// Vids
.video-placeholder {
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  @media print {
    margin-bottom: 0;
  }
}
.video-container {
  position: relative;
  padding-bottom: 56.25%; // 16/9eme
  height: 0;
  overflow: hidden;
  @media print {
    padding-bottom: 0;
    height: auto;
    overflow: auto;
  }
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media print {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    max-width: 100%;
    height: auto;
  }
}

/* Style Alerts */
.alert {
  padding: 14px 12px;
  display: flex;
  align-items: center;
  border-radius: 0;margin-bottom: 8px;
  p {
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 17px;
  }
}
.alert-danger{
  background: $bg-error-color;
  &:before {
    content: "\f071";
    font: normal normal normal 2em "FontAwesome";
    font-size: 2em;
    margin-right: 1rem;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
  p {
    color: $primary-color;
  }
} 
.alert-success { 
  p {
    color: $gray-color;
  }
}
/* Style bouton */
.btn,
button,
input[type=button],
input[type=submit] {
  font-size: 18px;
  font-family: $sans-font;
  border-radius: 0;
  background: $primary-color;
  color: $white-font-color;
  padding: 6px 12px;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  &:hover{
    color:$hover-font-color;
  }
  .fa {
    color: $white-font-color;
  }
}
.btn:hover {
  color: $light-grey-color;
}

select {
  font-size: 17px;
  padding: 2px 5px;
  border: 1px solid #b13727;
  cursor: pointer;
}
/* Style input type text & textarea */
input[type=text],
textarea {
  border: 1px solid $primary-color-border;
}
textarea {
  width: 100%;
}
/* Style Radio bouton */
input[type=radio],
.checkbox input {
  visibility: hidden;
}  
.radio input + label {
  font: normal normal normal 16px/1 FontAwesome;
  cursor: pointer;
}
.radio input + label::before {
  content: "\f10c";
  margin-right: 5px;
  color: $primary-color;
}
.radio input:checked + label::before {
  content: "\f192";
}

.checkbox input + label {
  font: normal normal normal 16px/1 FontAwesome;
  cursor: pointer;
  padding: 0;
}
.checkbox input + label::before {
  content: "\f096";
  margin-right: 5px;
  width: 16px;
  float: left;
  font-size: 16px;
  color: $primary-color;
}
.checkbox input:checked + label::before {
  content: "\f046";
}

/* Style Table */
table.stripped {
  font-family: $sans-font;
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    border: 1px solid $primary-color;
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even) {
    background-color: $primary-color;color:$white-font-color;
  }
}

.pagination-placeholder {
  text-align: center;
}
.pagination > li > a {
  color: $grey-color;
}
.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background-color: $primary-color;
  border-color: $grey-color;
}

/* Tabs */
.nav-tabs a {
  text-decoration: none;
}
.tab-pane {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* Signature */
.signature {
  display: block !important;
  font-size: .9em;
  text-align: right !important;
  color: $grey-color;
  font-style: italic;
  strong,
  b {
    font-weight: normal !important;
  }
  &:before {
    display: inline-block;
    content: "\2014\a0\a0";
  }
  &:after {
    display: inline-block;
    content: "\a0\a0\2014";
  }
}
