// ----------------------------------------------------
//  Style Home Page 
// ---------------------------------------------------- */

// style home
.home {
  em {
    display: inline-block;
    margin-left: 1.5em;
  }
  ol.breadcrumb {
    display: none;
  }
  ul.regular {
    display: block;
    list-style-type: none;
    margin: 0 0 2rem;
    padding: 0;
    width: 100%;
    clear: both;
    @extend .clearfix;
    li {
      display: block;
      clear: both;
      width: 100%;
      margin: 0 0 .5rem;
      padding: 0;
    }
  }
  .content {
    padding-bottom: 0;
    ul {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
      li {
        display: block;
        margin: 0;
        padding: 0;
        a {
          color: $third-color;
          font-size: 18px;
          font-weight: 500;
          &.all {
            text-align: right;
            display: block;
            font-size: 18px;
            font-style: italic;
            font-family: "Alegreya", serif;
            
            &:hover {
              color: $hover-link;
            }
          }
          &:hover {
            color: $hover-link;
          }
        }
        &:before {
          display: none;
        }
      }
    }
    a {
      text-decoration: none;
    }
    h1 {
      line-height: 1;
      @include wsize('laptop') {
        font-size: 24px;
      }
      @include wsize('desktop') {
        margin-bottom: 1.5rem;
      }
      &:before {
        @include wsize('laptop') {
          font-size: 21px;
          line-height: 21px;
        }
      }
    }
    h2 {
      line-height: 1;
      font-size: 18px;
      margin: 0;
    }
    p {
      font-weight: 300;
      color: $third-color;
      font-size: 1.6rem;
      line-height: normal;
    }
    .date,
    .media {
      font-size: 14px;
      font-size: 1.6rem;
      line-height: 1;
    }
    .date {
      font-weight: 500;
      display: block;
      float: right;
      margin-left: 1em;
      text-align: right;
    }
    .media {
      color: $gray-color;
      font-style: italic;
    }
    .actualites {
      margin-bottom: 2rem;
      text-align: justify;
      h1 {
        background: $bg-fourth-color;
        margin-bottom: 0;
        text-align: center;
        color: $white-font-color;
        font-size: 24px;
        padding: .75rem;
        @include wsize('desktop') {
          margin-top: 1.5rem;
        }
        &:before {
          display: none;
        }
        a {
          color: inherit;
          display: block;
        }
      }
      a {
        color: $fourth-color;
        font-size: 21px;
        &.all {
          color: $fourth-color;
          font-size: 22px;
        }
        &:hover {
          color: $hover-link;
        }
      }
      @include wsize('laptop') {
        margin-bottom: 0;  
      }
      .cont {
        padding: 1.5rem 15px 0;
        margin-bottom: 2rem;
        background: $bg-actualite-color;
        overflow: hidden;
        ul {
          float: left;
          width: 100%;
          display: block;
          list-style-type: none;
          @extend .clearfix;
          li {
            display: block;
            width: 100%;
            float: left;
            h2 {
              margin-bottom: .5rem;
            }
            .actu-img {
              display: block;
              width: 100%;
              height: auto;
              max-width: 100%;
              margin: 0 15px 1.5rem 0;
              &:hover {
                opacity: 0.9;
              }
              a {
                display: block;
                text-align: center;
              }
              img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 90px;
                margin-left: auto;
                margin-right: auto;
              }
              @include wsize('tablet') {
                float: left;
                width: calc(25% - 7.5px);
                margin-right: 15px;
                &.video {
                  float: right;
                  width: calc(50% - 7.5px);
                  margin-right: 0;
                  margin-left: 15px;
                }
              }
            }
            .actu-txt {
              margin-bottom: 1.5rem;
              @include wsize('tablet') {
                float: left;
                width: calc(75% - 7.5px);
                &.video {
                  width: calc(50% - 7.5px);
                }
              }
            }
            .video-container {
              position: relative;
              margin-bottom: 0;
              img {
                width: auto;
                height: auto;
                max-width: 100%;
                display: block;
                position: relative;
                z-index: 1;
                margin-top: -1.5px;
                max-height: none;
              }
              a {
                position: relative;
                display: flex;
                align-items: center;
                z-index: 1;
              }
              .fa-play-circle {
                position: absolute;
                z-index: 2;
                font-size: 78px;
                margin: 0 auto;
                text-align: center;
                right: 0;
                left: 0;
                color: $bg-fourth-color;
                //opacity: 0.9;
                text-shadow: 0 0 20px rgba($dark-color, .6);
                @extend .clearfix;
                @include wsize('laptop') {
                  font-size: 40px;
                }
              }
              a:hover {
                .fa-play-circle {
                  opacity: .5;
                }
                img {
                  opacity: .9;
                }
              }
            }
            &.portrait {
              .actu-img {
                @include wsize('tablet') {
                  width: calc(15% - 7.5px);
                }
              }
              .actu-txt {
                @include wsize('tablet') {
                  width: calc(85% - 7.5px);
                }
              }
            }
          }
        }
      }
      @include wsize('tablet') {
        padding: 0 15px;
      }
    }
    .veille-presse-content {
      margin-bottom: 2rem;
      @include wsize('tablet') {
        ul.regular.abonne-rdm {
          li {
            clear: left;
            width: 48.625%;
            float: left;
            margin-right: 1.375%;
            &:nth-child(even) {
              clear: none;
              margin-left: 1.375%;
              margin-right: 0;
            }
            &:last-child {
              width: 100%;
              clear: both;
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }
    .flash-ilec {
      padding: 1.5rem 1.5rem 0;
      border: 1px solid $secondary-color;
      margin-bottom: 2rem;
      h1 {
        &:before {
          display: none;
        }
        a {
          display: block;
          outline: none;
          text-indent: -9999em;
          background: url('../medias/img/flash-ilec.svg') no-repeat 50% 50%/100% 100%;
          width: 205px;
          height: 54px;
          margin: 0 auto;
          @include wsize('laptop') {
            width: 180px;
            height: 50px;
          }
          @include wsize('desktop') {
            width: 205px;
            height: 54px;
          }
        }
      }
      ul {
        text-align: center;
        padding: 0;
        li {
          text-align: left;
          a {
            font-weight: normal;
            color: $base-font-color;
            line-height: normal;
            font-size: 14px;
            &:hover {
              color: $hover-link;
            }
            span {
              display: block;
              font-size: 18px;
              font-weight: 500;
              color: $primary-color;
            }
            &.all.flash {
              color: $flash-ilec-color;
            }
            &.all.flash:hover,
            &:hover span {
              color: $hover-link;
            }
          }
        }
      }
      @include wsize('tablet') {
        background-size: 80% auto;
      }
    }
    .carousel-bibliotheque {
      padding-top: 0;
      position: relative;
      margin-top: 1rem;
      margin-bottom: 4rem;
      h1 {
        padding: 5px 0;
        color: $white-font-color;
        span {
          display: block;
          font-size: 24px;
          line-height: 24px;
          margin-left: 20px;
          height: 30px;
          background: $bg-primary-color;
          padding: 1px 15px;
          &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: 2px;
            border: 15px solid #fff;
            width: 0px;
            background: #fff;
            border-radius: 30px;
          }
          a {
            color: $white-font-color;
          }
        }
        &:before {
          margin-top: 3px;
          z-index: 999;
          position: absolute;
          color: $primary-color;
          font-size: 31px;
          @include wsize('tablet') {
            margin-top: 3px;
          }
          @include wsize('laptop') {
            margin-top: 5px;
          }
        }
      }
      .carousel-showmanymoveone {
        .carousel-control {
          width: 16px;
          height: 16px;
          top: -38.5px;
          background: $white-font-color; 
          background-image: none;
          opacity: 1;
          border-radius: 2px;
          &.right { 
            right: 19px;
          }
          &.left {
            right: 45px;
            left: inherit;
          }
          @include wsize('desktop') {
            top: -43.5px;
          }
          .glyphicon-chevron-left,
          .glyphicon-chevron-right {
            font-size: 12px;
            line-height: 30px;
            text-shadow: none;
            color: #999999;
            margin-right: -6px;
            margin-top: -15px;
            @include wsize('tablet') {
              margin-right: -15px;
            }
          }
          .glyphicon-chevron-left,
          .icon-prev {
            margin-left: -6px;
            @include wsize('tablet') {
              margin-left: -15px;
            }
          }
          &:hover {
            opacity: 0.7;
          }
        }
        .cloneditem-1, 
        .cloneditem-2, 
        .cloneditem-3 {
          display: none;
        }
        .item {
          div {
            padding-left: 0;
          }
          h4 {
            font-size: 18px;
            font-weight: 500;
            padding-left: 10px;
            border-left: 2px solid $third-color;
            a {
              color: $third-color;
              &:hover {
                color: $hover-link;
              }
            }
          }
          p {
            font-size: 1.6rem;
            line-height: 1.2em;
          }
          .img-link {
              display: block;
              float: left;
              margin-right: 25px;
            img {
              width: 100px;
              max-width: 100px;
              height: auto;
              @include wsize('tablet') {
                width: 75px;
                max-width: 75px;
              }
            }
          }
          .abstract.item-has-a-thumb {
            font-size: 1.6rem;
            line-height: 1.2em;
            margin-left: 125px;
            @include wsize('tablet') {
              margin-left: 100px;
            }
          }
        }
        .item,
        .cloneditem {
          min-height: 20rem;
        }
        .carousel-inner {
          @include wsize('tablet') {
            @media (transform-3d),
            (-webkit-transform-3d) {
              > .item.active.right,
              > .item.next { 
                transform: translate3d(50%, 0, 0);  
                left: 0;
              }
              > .item.active.left,
              > .item.prev { 
                transform: translate3d(-50%, 0, 0);
                left: 0;
              }
              > .item.left,
              > .item.prev.right,
              > .item.active {
                transform: translate3d(0, 0, 0);
                left: 0;
              }
            } 
            > .active.left,
            > .prev {
              left: -50%;
            }
            > .active.right,
            > .next {
              left:  50%;
            }
            > .left,
            > .prev.right,
            > .active {
              left: 0;
            }
            .cloneditem-1 {
              display: block;
            }
          }
          @include wsize('laptop') {
            @media (transform-3d), (-webkit-transform-3d) {
              > .item.active.right,
              > .item.next { 
                transform: translate3d(25%, 0, 0);  
                left: 0;
              }    
              > .item.active.left,
              > .item.prev { 
                transform: translate3d(-25%, 0, 0);
                left: 0;
              }
              > .item.left,
              > .item.prev.right,
              > .item.active {
                transform: translate3d(0, 0, 0);
                left: 0;
              }
            }
            > .active.left,
            > .prev {
              left: -25%;
            }
            > .active.right,
            > .next {
              left:  25%;
            }
            > .left,
            > .prev.right,
            > .active {
              left: 0;
            }
            .cloneditem-2, 
            .cloneditem-3 {
              display: block;
            }
          }    
        }
      }
    }

  }
  // Color theming
  .veilles-presse {
    h1,
    h1 a,
    .date,
    .all {
      color: $veille-presse-color;
    }
    h1 a:hover {
      color: $hover-link;
    }
  }
  .presentations {
    h1,
    h1 a,
    .date,
    .all {
      color: $presentation-color;
    }
    h1 a:hover {
      color: $hover-link;
    }
  }
  .comptes-rendus {
    h1,
    h1 a,
    .date,
    .all {
      color: $compte-rendu-color;
    }
    h1 a:hover {
      color: $hover-link;
    }
  }
  .calendriers {
    h1,
    h1 a,
    .date,
    .all {
      color: $calendrier-color;
    }
    h1 a:hover {
      color: $hover-link;
    }
  }
  .ordres-du-jour {
    h1,
    h1 a,
    .date,
    .all {
      color: $ordre-du-jour-color;
    }
    h1 a:hover {
      color: $hover-link;
    }
  }
  .indices-et-conjonctures {
    h1,
    h1 a,
    .date,
    .all {
      color: $indice-et-conjoncture-color;
    }
    h1 a:hover {
      color: $hover-link;
    }
  }
  .bonnes-pratiques {
    h1,
    h1 a,
    .date,
    .all {
      color: $fourth-color;
    }
    h1 a:hover {
      color: $hover-link;
    }
  }
  .ateliers {
    h1,
    h1 a,
    .date,
    .all {
      color: $atelier-color;
    }
    h1 a:hover {
      color: $hover-link;
    }
    section:nth-child(2) {
      h1,
      h1 a {
        color: $liste-membres-color;
      }
      h1 a:hover {
        color: $hover-link;
      }
    }
  }
  @include wsize('desktop') {
    .veilles-presse h1,
    .presentations h1 {
      margin-top: 2.5rem;
    }
  }
  ul.regular.indices-list {
    li {
      display: block;
      float: left;
      width: 100%;
      margin-bottom: 1.5rem;
      img {
        width: 25%;
        float: left;
      }
      h2.with-thumb,
      p.with-thumb {
        margin-left: calc(25% + 15px);
      }
    }
  }

}

