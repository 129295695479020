/* ----------------------------------------------------
    Style login page 
  ------------------------------------------------------- */
.login-page {
  background: $bg-secondary-color;
  .sideBar,
  footer,
  header nav,
  .headerBottom,
  .header-bottom,
  .recherche {
    display: none;
  }
  .content {
    padding-top: 0;
  }
}

.login-content { 
  .form-login { 
    width: 100%;
    @include wsize('tablet') {
      width: 400px;
      margin: 25px auto;
    }
    .login { 
      padding: 0px 30px;
  /*    span.un {
        margin-right: 13px;
        text-align: center;
        color: $white-font-color;
        display: inline-block;
        vertical-align: top;
        i { 
          &.fa,
          &:before {
            color: $white-font-color;
          }
        }
      }*/
      ul {
        margin-left: 0;padding-left: 0;
        li {
          padding-bottom: 2px;
          margin: 15px 0 10px;
          list-style: none;
          &.input {
            border-bottom: 1px solid $white-font-color;
            margin: 29px 0;
            padding-left: 5px;
            padding-bottom: 0px;
            &:before {
                 
              }
          }
          &.pseudo{
            &:before {
                  content: "\f007";color: $white-font-color;margin-right: 1rem;font: normal normal normal 1em "FontAwesome";
              }
          }
          &.passe{
            &:before {
                  content: "\f023";color: $white-font-color;margin-right: 1rem;font: normal normal normal 1em "FontAwesome";
              }
          }
          &.btn-connect{text-align: center;}
          .text { 
            background: none;
            color: $white-font-color;
            display: inline-block;
            vertical-align: top;
            border: none;
            outline: none;
            line-height: 19px;
            font-size: 12px;
            font-family: $sans-font;
            min-width: 230px;
            width: 90%;
            background: transparent !important;
            &::-webkit-input-placeholder { /* WebKit, Blink, Edge --> changer la couleur de placeholder*/
              color: $white-font-color;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
              color: $white-font-color;
              opacity:  1;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
              color: $white-font-color;
              opacity:  1;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $white-font-color;
            }
            @include wsize('tablet') {
              font-size: 14px;
            }
          } 
          .btn {
            height: 44px;
            border: none;
            width: 100%;
            outline: none;
            margin-top: 7px;
            max-width: 400px;
            &:hover {
              color: $hover-font-color;
            }
          }
          .span { 
            width: 100%;
            font-size: 14px;
            .ch { 
              float: left;
              width: 50%;
              color: $white-font-color;
              a { 
                color: $white-font-color;
                text-decoration: none;
                letter-spacing: 0.4px;
                font-size: 10px;
                @include wsize('tablet') {
                  font-size: 12px;
                }
              }
              &:nth-child(1) {
                height: 29px;
                display: flex;
                align-items: center;
              }
              &:nth-child(2) {
                text-align: right;
              }
              input#r {
                float: right;
                margin-left: 5px;
                margin-top: 2px;
              }
            }
          }
          span.bigcheck-target {
            font-family: "FontAwesome";
            font-size: 15px;
            float: right;
            margin-top: 4px;
            width: 17px;
            min-width: 17px;
            height: 17px;
            border: 1px solid $bg-white-color;
          }
          input[type='checkbox'].bigcheck {
            position: relative;
            left: -999em;
          }
          label.bigcheck {
            font-size: 10px;
            letter-spacing: 0.2px;
            line-height: 29px;
            @include wsize('tablet') {
              font-size: 12px;
            }
          }
          input[type='checkbox'].bigcheck + span.bigcheck-target:after {
            color: $bg-secondary-color;
            font-size: 14px;
          }
          input[type='checkbox'].bigcheck:checked + span.bigcheck-target:after {
            content: "\f00c";
            width: 15px;
            color: white;
            position: relative;
            bottom: 8px;
          }
          &.passOublie {
            margin-top: 22px;
          } 
        }
      }
    }
    .header { 
      h2 { 
        font-weight: normal;
        font-size: 12px;
        line-height: 25px;
        color: $white-font-color;
        text-align: center;
        letter-spacing: 0.6px;
        @include wsize('tablet') {
          font-size: 15px;
        }
      } 
    }

    .public-redirection {
      padding-top: 2em;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      color: $white-font-color;
      a {
        color: $white-font-color;
      }
    }
  }
  .message { 
    padding: 10px 30px;
    margin: 5px auto 5px;
    @include wsize('tablet') {
      max-width: 400px;
    }
  } // End messages d'erreurs
}
input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0 100px $bg-secondary-color inset;
   -webkit-text-fill-color: $white-color;
}
input:-webkit-autofill:focus {
   -webkit-box-shadow: 0 0 0 100px $bg-secondary-color inset;
   -webkit-text-fill-color: $white-color;
}
