/* Font Colors */ 
$base-font-size: 10px;
$content-font-size: 1.8rem;
$base-font: "Helvetica", "Arial", sans-serif;
$sans-font: "Alegreya Sans", sans-serif;
$serif-font: "Alegreya", serif;
$white-color: #fff;
$grey-color: #6e6e6e;
$base-font-color: #000;
$white-font-color: $white-color;
$menu-font-color: #333333;
$hover-font-color: #ccc;
$hover-link: #23527c;
$primary-color: #b13727;
$third-color: #1b1b1b;
$primary-color-border:#940e11;
$breadcrumb-blockquote-border-color: #707172;
$gray-color: #6e6e6e;
$light-grey-color: #ccc;
$light-gray-color: $light-grey-color;
$almost-white-color: #eee;
$secondary-color: #e4ae11;//#eabc2c; /* jaune */
$light-color: #808080;
$dark-color: #000000;
$regular-text-color: #000; /* Titres niveau 2 (h2) & textes & listing & blockquote ... pages article/Type/...  */
$fourth-color: #aca1d3;
/* Background Colors */
$bg-white-color: #fff;
$bg-primary-color: #b13727;
$bg-secondary-color: #383737;
$bg-gray-color: $grey-color;
$bg-grey-color: $grey-color;
$bg-light-gray-color: $light-grey-color;
$bg-light-grey-color: $light-grey-color;
$bg-error-color: #f9cfcb;
$bg-actualite-color: #EAE7F4;
$bg-fourth-color: #aca1d3;
$bg-headline: $almost-white-color;
$revue-color: #7996d6;


$veille-presse-color: $primary-color;
$actualite-color: $fourth-color;
$presentation-color: $primary-color;
$compte-rendu-color: $primary-color;
$calendrier-color: $secondary-color;
$ordre-du-jour-color: $primary-color;
$indice-color: $primary-color;
$conjoncture-color: $indice-color;
$indice-et-conjoncture-color: $indice-color;
$bonne-pratique-color: $fourth-color;
$atelier-color: $primary-color;
$bibliotheque-color: $primary-color;
$flash-ilec-color: $secondary-color;
$liste-membres-color : $secondary-color;

$img_width: 90px;

$italic_letter_spacing: .3333px;
