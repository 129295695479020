// * {
//   margin: 0;
//   padding: 0;
// }

body {
  font-size: 10px;
}

a {
  color: $primary-color;
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
}
small {
  font-size: .8em;
}
input,
textarea,
button {
  @include border-radius(0px);
}
p { 
  color: $third-color;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto;
}
.fa {
  color: $primary-color;
}
.secondary-color .fa {
  color: $secondary-color;
}
