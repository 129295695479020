/* Foundation v2.1.4 http://foundation.zurb.com */
/* Artfully masterminded by ZURB  */

/* -----------------------------------------
   Shared Styles
----------------------------------------- */

/* Mobile */
@media only screen and (max-width: 767px) {
  table.responsive {
    margin-bottom: 0;
  }
  .pinned {
    position: absolute;
    left: 0;
    top: 0;
    width: 40%;
    overflow: hidden;
    overflow-x: scroll;
  }
  .pinned table {
    border-right: none;
    border-left: none;
    width: 100%;
  }
  //.pinned table th,
  //.pinned table td { white-space: nowrap; }
  .pinned td:last-child {
    border-bottom: 0;
  }
  div.table-wrapper {
    position: relative;
    margin-bottom: 2rem;
    overflow: hidden;
  }
  div.table-wrapper div.scrollable table {
    margin-left: 40%;
  }
  div.table-wrapper div.scrollable {
    overflow: scroll;
    overflow-y: hidden;
  }
  table.responsive td,
  table.responsive th {
    //position: relative;
    overflow: hidden;
    span {
      white-space: nowrap;
    }
  }
  table.responsive td {
    height: 8rem;
  }
  table.responsive th:first-child,
  table.responsive td:first-child,
  table.responsive td:first-child,
  table.responsive.pinned td {
    display: none;
  }
  .pinned th {
    height: 8rem;
  }
  .pinned tr:first-child th {
    height: 1rem;
  }
}
