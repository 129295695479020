/*  header  */
.site-header { 
  background: $bg-white-color;
  margin-bottom: 1rem;
  .site-header-main{
    position: relative;
    .navigation-top { 
      background: $bg-white-color;
      width: 100%;
      padding: 20px 0;
      .logo {
        a { 
          background: url('../medias/img/logo-mobile.svg') no-repeat;
          display: block;
          width: 180px;
          height: 46px;
          background-size: cover;
          text-decoration: none;
          @include wsize('tablet') {
            background: url('../medias/img/logo-tablette.svg') no-repeat;
            display: block;
            width: 383px;
            height: 45px;
            margin-left: 0;
            background-size: cover;
          }
          @include wsize('laptop') {
            background: url('../medias/img/logo.svg') no-repeat;
            display: block;
            width: 420px;
            height: 49px;
            background-size: cover;
          }
        }
        &.abonne {
          a {
            display: inline-block;
            background: none;
            line-height: 60px;
            vertical-align: middle;
            &:before {
              display: inline-block;
              content: "Site réservé aux abonnés de la Revue des Marques";
              color: #A8A8A8;
              font-size: 2rem;
              font-weight: bold;
              line-height: 1;
              vertical-align: middle;
              @include wsize('tablet') {
                font-size: 2.25rem;
              }
            }
            @include wsize('laptop') {
              margin-left: 0;
            }
            &:hover {
              &:before {
                color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
  .recherche { 
    width: 100%;
    background: $bg-primary-color;
    padding: 7px 0;
    margin-bottom: 10px;
    .btn-spacer {
      margin-top: .7rem;
      @include wsize('tablet') {
        margin-top: 0;
      }
    }
    .input-group { 
      display: block;
      input { 
        border-radius: 0;
        text-align: center;
        color: $light-color;
        font-size: 14px;
        width: 100%;
        height: 41px;
        border-color: #e4e4e4;
        box-shadow: inset 0px 0px 26px 0px rgba(0,0,0,0.2);
        margin-bottom: 2px;
        @include wsize('tablet') {
          font-size: 18px;
          margin-bottom: 0;
        }
      }
      span { 
        button { 
          border-radius: 0;
          border:1px solid $primary-color-border;
          background: none;
          color: $white-font-color;
          &:hover{color:$hover-font-color;}
        }
      }
      .input-group-btn {
        .btn { 
          font-size: 13px;
          padding: 7px 15px;
          width: 100%;
          height: 41px;
          @include wsize('tablet') {
            font-size: 14px;
          }
          @include wsize('laptop') {
            font-size: 16px;
          }
          i { 
            color: $white-font-color;
            font-size: 12px;
            &:before {
              color: $white-font-color;
            }
            @include wsize('tablet') {
              font-size: 18px;
            }
          }
        }
      }
    }
    @include wsize('tablet') {
      display: block;
      padding: 13px 0;
    }
  }
}

.header-bottom {
  margin-bottom: 1rem;
  .domain-filter-global {
    .btn-lbl {
      height: 2rem;
      line-height: 2rem;
      font-size: 1.5rem;
    }
    .btn-group.domain-filter {
      width: 100%;
    }
    @include wsize('tablet'){
      padding-left: 0;
    }
    @include wsize('laptop'){
      padding-left: 15px;
    }
  }
  .row {
    display: flex;
    flex-direction: column-reverse;
    @include wsize('tablet'){
      display: inherit;
      flex-direction: inherit; 
    }
  }
}
.btn-group.domain-filter {
  .btn.btn-domain-filter {
    width: 100%;
    position: relative;
    @include border-radius(0);
    font-family: $sans-font;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    @include wsize('tablet') {
      letter-spacing: 0;
    }
    @include wsize('laptop') {
      font-size: 14px;
    }
    @include wsize('desktop') {
      font-size: 15px;
      letter-spacing: 1px;
    }
    color: $white-font-color;
    background: $bg-gray-color;
    text-align: left;
    padding-top: 12px;
    padding-bottom: 12px;
    /*margin-bottom: 1rem;*/
    .caret {
      display: block;
      float: right;
      border-top-width: 7px;
      border-left-width: 5px;
      border-right-width: 5px;
      /*margin-top: 7px;*/
      position: absolute;
      top: 2rem;
      right: 1.5rem;
    }
  }
  .dropdown-menu {
    min-width: 100%;
    @include border-radius(0);
    @include add-border(1px, $gray-color, "all");
    margin: 1px 0 0;
    padding-top: 0;
    padding-bottom: 0;
    li {
      font: normal normal normal 14px/1 FontAwesome, "Alegreya Sans", sans-serif;
      background-color: inherit;
      border-bottom: 1px solid $bg-light-gray-color;
      padding: 0;
      margin: 0;
      &:before{content:"";}
      &.active{
        &:before{content: "\f111";color: $primary-color; float: left;margin-top: 9px;left: 0;margin-left: 4px;}
        a{color: $primary-color;background: none ;}
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .dropdown-menu > li > a {
    white-space: normal;
    text-decoration: none;
    clear: inherit;
    padding: .6rem 1rem .6rem 2rem;
  } 
}
