// _article.scss

.article { 
  // Header
  @include wsize('laptop') {
    .article-date {
      margin-left: 2rem;
      white-space: nowrap;
      display: block;
      float: right;
      font-size: 1.6rem;
    }
  }
  h1 {
    font: normal normal normal 2rem/1 $serif-font;
    font-weight: 100;
    color: $primary-color;
    margin-bottom: 1em;
    text-align: justify;
    span {
      display: block;
      &.surtitre {
        color: $gray-color;
        margin-bottom: .5em;
      }
      &.titre {
        @include wsize('tablet') {
          display: flex;
          font-size: 24px;
        }
        &.flashilec {
          font-size: 20px;
        }
        &:before {
          @include fa(1em);
          content: "\f111";
        }
      }
      &.sous-titre {
        font-size: 18px;
        margin-top: 1rem;
        @include wsize('tablet') {
          margin-left: 32px;
        }
      }
    }
  }
  .article-categories {
    margin-bottom: 2rem;
    &:before {
      @include fa(18px);
      content: "\f114";
      color: $primary-color;
      @include wsize('laptop') {
        display: block;
        float: left;
        width: 8%;
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-block;
      @include wsize('laptop') {
        display: block;
        float: left;
        width: 70%;
      }
      li {
        display: inline-block;
        color: $primary-color;
        a{text-decoration: none;}
      }
    }
    .article-date {
      display: inline-block;
      color: $primary-color;
      margin-left: 1rem;
      span {
          position: relative;
          top: -2px;
      }
    }
  }
  .footer {
    border-top: solid 1px $light-grey-color;
    margin-top: 2rem;
    padding-top: 2rem;
    font-size: 1.6rem;
  }
  p.press-review-article-link {
    font-size: 1.8rem;
    border-bottom: solid 1px $light-gray-color;
    padding-bottom: 1em;
    font-weight: bold;
    .fa {
      margin-right: .5em;
    }
    a {
      text-decoration: none;
      &[target="_blank"]:after {
        display: inline-block;
        margin-left: .5em;
        font-size: .6666em;
        vertical-align: super;
        content: "\f08e";
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}

.article-footer {
  * {
    display: inline;
  }
  color: $grey-color;
  @include wsize('tablet') {
    text-align: right;
  }
  .imprime {
    margin-left: 2rem;
    white-space: nowrap;
  }
  .date {
    &:before {
      display: inline-block;
      margin-left: 2rem;
      content: "\f044\a0";
      font-family: FontAwesome;
      display: inline-block;
      margin-right: .25em;
    }
  }
  dl {
    display: inline-block;
    padding: 0;
    margin: 0 0 0 2rem;
    &:first-child {
      margin-left: 0;
    }
    &:before {
      font-family: "FontAwesome";
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      color: $primary-color;
    }
    &.event-over:before {
      content: "\f235\a0";
    }
    &.categories {
      display: block;
      text-align: left;
      margin-bottom: 2rem;
    }
    &.categories:before {
      content: "\f0e8\a0";
    }
    &.themes:before {
      content: "\f114\a0";
    }
    &.tags:before {
      content: "\f02c\a0";
    }
    dt {
      display: none;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li:after {
      content: ",";
    }
    li:last-child:after {
      content: "";
    }
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
  a.print-link {
    display: block;
    float: left;
    text-decoration: none;
  }
}
h6.attachments-title {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0 0 1rem;
  padding: 0;
  color: $primary-color;
  &:before {
    display: inline-block;
    font-family: "FontAwesome";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: "\f0c6";
    margin-right: .5rem;
    font-size: 125%;
  }
}
.attachments {
  display: block;
  list-style-type: none;
  margin: 0 0 2rem 0;
  padding: 0;
  li {
    display: inline-block;
    margin: 0 2rem 0 0;
    padding: 0;
    a {
      display: block;
      text-decoration: none;
      position: relative;
      color: $grey-color;
      &:before {
        display: inline-block;
        font-family: "FontAwesome";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        color: $grey-color;
        content: "\f016";
        font-size: 125%;
        margin-right: .5rem;
      }
      &:hover {
        color: $dark-color;
      }
      &:hover:before {
        color: $primary-color;
      }
      &[href$=".pdf"]:before {
        content: "\f1c1";
      }
      &[href$=".doc"]:before,
      &[href$=".docx"]:before {
        content: "\f1c2";
      }
      &[href$=".xls"]:before,
      &[href$=".xlsx"]:before {
        content: "\f1c3";
      }
      &[href$=".ppt"]:before,
      &[href$=".pptx"]:before {
        content: "\f1c4";
      }
      &[href$=".zip"]:before,
      &[href$=".rar"]:before,
      &[href$=".7z"]:before,
      &[href$=".ace"]:before {
        content: "\f1c6";
      }
      &[href$=".jpg"]:before,
      &[href$=".jpeg"]:before,
      &[href$=".gif"]:before,
      &[href$=".png"]:before,
      &[href$=".bmp"]:before,
      &[href$=".tif"]:before,
      &[href$=".tiff"]:before,
      &[href$=".psd"]:before,
      &[href$=".eps"]:before,
      &[href$=".ai"]:before,
      &[href$=".svg"]:before {
        content: "\f1c5";
      }
      &[href$=".avi"]:before,
      &[href$=".mpg"]:before,
      &[href$=".mpeg"]:before,
      &[href$=".mp4"]:before,
      &[href$=".flv"]:before,
      &[href$=".mov"]:before,
      &[href$=".wmv"]:before,
      &[href$=".divx"]:before,
      &[href$=".xvid"]:before,
      &[href$=".mkv"]:before,
      &[href$=".rmvb"]:before {
        content: "\f1c8";
      }
      &[href$=".wav"]:before,
      &[href$=".mp3"]:before,
      &[href$=".wma"]:before,
      &[href$=".ogg"]:before,
      &[href$=".flac"]:before,
      &[href$=".aac"]:before {
        content: "\f1c7";
      }
    }
  }
}
