.sidebar { 
  &.sidebar-article {
    clear: both;
    margin: 3rem 0;
    @include wsize('laptop') {
      margin-top: 0;
      margin-bottom: 0;
    }
    i.fa { 
      color: $secondary-color;
      font-size: 17px;
    }
    h3 {
      color: $secondary-color;
      font-size: 22px;
      font-family: $serif-font;
      line-height: 1em;
      margin-top: 1rem;
      margin-bottom: 2rem;
      display: flex;
      &:before {
        @include fa(1em);
        content: "\f111";
      }
    }
    ul { 
      margin-left: 0;
      margin-bottom: 1rem;
      padding: 0;
      list-style-type: none;
      li {
        display: inherit;
        font-size: 18px;
        font-weight: 300;
        padding: 0 0 0 2rem;
        margin-bottom: 1rem;
        line-height: 1.2em;
        &:before { 
          font-family: "FontAwesome";
          content: "\F101";
          color: $primary-color;
          margin-right: 1rem;
          font-size: 1em;
          position: absolute;
          left: 15px;
          /*margin-top: -2px;*/
        }
        a {
          text-decoration: none;
          color: inherit;
          display: block;
          &.title-article {
            width: 100%;
          }
        > a{width: 100%;}
          span {
            display: block;
            font-weight: 400;
            margin-bottom: .5rem;
          }
          &:hover span {
            color: $primary-color;
          }
        }

        ul {
          li {
            display: block;
            padding-left: 0;
            &:before {
              display: none;
            }
            a {
              &:hover {
                    color: $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

.sidebar.treeview {
  padding: 1.5rem;
  //border: solid 1px $bg-headline;
  box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.25);
  margin-top: 1rem;
  margin-bottom: 2rem;
  h1 {
    color: $primary-color;
    border-bottom: solid 1px $hover-font-color;
    font-size: 2rem;
    float: none;
    clear: both;
    &:before {
      content: none;
    }
  }
  a {
    text-decoration: none;
    color: $menu-font-color;
    &:hover {
      color: $primary-color;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      padding: 0;
      margin: 0 0 0 1.3rem;
      &:before {
        position: relative;
        margin-left: -1rem;
        font-size: 120%;
        display: inline-block;
        content: "\bb";
        color: $primary-color;
      }
      a {
        color: $base-font-color;
      }
      &.active > a {
        color: $primary-color;
        font-weight: bold;
      }
      li {
        a {
          color: $grey-color;
        }
      }
    }
  }
  & > section > nav > ul > li > a {
    font-size: 110%;
  }
}

.abc {
  li {
    display: inline-block;
    &:before {
      display: none;
    }
  }
}
