.headline-global {
  display: none;
  background: $bg-headline;
  position: inherit;
  @include wsize('tablet') {
    margin-top: 2rem;
  }
  .headline {
    border-radius: 0;
    font-weight: 500;
    padding: 12px 15px;
    width: 100%;
    background: $bg-headline;
    list-style-type: none;
    margin: 0 0 1rem;

    > div {
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0.5px;
      margin: 0;
      p {
        margin: 0;
      }
      a {
        text-decoration: none;
      }
      @include wsize('tablet') {
        font-size: 16px;
        padding: 0.5px 0;
      }
      @include wsize('laptop') {
        font-size: 16px;
      }
    }
    @include wsize('tablet') {
      margin: 0;
      padding: 0;
    }
    @include wsize('tablet') {
      padding: 0px 15px;
      position: relative;
    }
    @include wsize('laptop') {
      padding: 10.5px 15px;
    }
  }
  @include wsize('tablet') {
    padding-right: 0;
    background: none;
    position: relative;
  }
  @include wsize('laptop') {
    padding-right: 15px;
  }


}
.home{
  .header-bottom{
    .col-sm-push-8{
      left: 0;
      @include wsize('tablet') {
        /*padding-left: 0;*/
      }
    }
    @include wsize('tablet') {
      padding-top: 0;
    }
    .row {
      display: inherit;
      flex-direction: inherit;
    }
  }
  .headline-global{
    display: block;
    em {
      display: inline;
      margin-left: auto;
    }
  }
  .breadcrumb{
    display: none;
  }
}
